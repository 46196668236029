import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import queryString from 'query-string'
import React, { useContext, useEffect, useState } from 'react'

import Layout from '../components/Layout'
import StaticHeader from '../components/StaticHeader'
import SEO from '../components/seo'
import ShopExplore from '../components/shop-explore'
import { TopMagin } from '../styles/static.js'

const SectionContainerTop = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  background-color: rgb(var(--${(props) => props.color}));
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  @media (max-width: 1025px) {
    ${(props) => (props.mobilePadding ? 'padding: 32px 0px;' : '')};
    flex-direction: ${(props) =>
      props.reverseMobile ? 'column-reverse' : 'column'};
  }
  @media (min-width: 1024px) {
    ${(props) => (props.minHeight ? `min-height: ${props.minHeight};` : '')}
    ${(props) => (!props.noMin ? 'height: calc(100vh - 126px);' : '')}
    ${(props) => (props.negativeMargin ? 'height: calc(75vh - 126px);' : '')}
    ${(props) => (props.maxWidth ? 'max-width: 1200px; margin: 0 auto;' : '')}
  }
`

const SectionCopy = styled.div`
  width: ${(props) => (props.short ? '45%;' : '50%;')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1025px) {
    width: 100%;
    text-align: left;
    padding: 32px 32px;
    z-index: 2;
    ${(props) => (props.left ? 'align-items: flex-start;' : '')};
    ${(props) => (props.lower ? 'padding: 32px 16px;' : '')};
    ${(props) => (props.noTopPadding ? 'padding-top: 0;' : '')}
    ${(props) =>
      props.center ? 'text-align: center; align-items: center;' : ''}
    ${(props) =>
      props.mobileAbsolute
        ? 'position: absolute; top: 22px; padding-top: 8px;'
        : ''}
  }
`
const CopyHeading = styled.div`
  font-size: 35px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: -1.11px;
  color: rgb(var(--${(props) => (props.color ? props.color : 'black')}));
  padding: 16px 0;
  @media (max-width: 1025px) {
    font-size: 25px;
    font-weight: 500;

    color: rgb(var(--${(props) => (props.color ? props.color : 'black')}));
    ${(props) =>
      props.mobileLeft ? 'text-align: left;padding-right: 15%;;' : ''}
  }
`

const SmallSubHeading = styled.div`
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.71px;
  color: rgb(var(--${(props) => props.color}));
  padding: 16px 0;
  @media (max-width: 1025px) {
    padding: 0;
  }
`
const CopyBody = styled.div`
  margin: 16px 0;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.12px;
  color: rgb(var(--black));
  @media (max-width: 1025px) {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: -0.1px;
    text-align: center;
    max-width: 280px;
    color: #282828;
    margin: 0 auto;
    ${(props) =>
      props.mobileLeft
        ? 'text-align: left;max-width: 2030px;padding-right: 15%;'
        : ''}
  }
`

const HeaderImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  ${(props) => (props.padding ? 'margin: 5%;width: 40%;' : '')}
  ${(props) =>
    props.sidePadding ? 'margin: 0 7.5%;width: 35%;margin-top: 15%;' : ''}
  ${(props) => (props.above ? 'margin-top: -5%;' : '')}
  ${(props) => (props.below ? 'margin-bottom: -5%;' : '')}
  ${(props) => (props.smallPad ? 'margin: 96px 0;' : '')}
  @media (max-width: 1025px) {
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    ${(props) =>
      props.sidePadding ? 'width: 90%; margin: -32px 5%; margin-top: 5%;' : ''}
  }
`
const HeaderCopyHeading = styled.div`
  font-size: 35px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.98;
  letter-spacing: -0.7px;

  color: rgb(var(--very-light-pink));
  padding: 16px 0;
  ${(props) => (props.color ? `color: rgb(var(--${props.color}));` : '')}

  ${(props) =>
    props.large
      ? `
    font-size: 51px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -1.26px;
    text-align: center;
    color: #ffffff;
  `
      : ''}
  @media (max-width: 1024px) {
    font-size: 32px;
  }
`
const HeaderCopySubHeading = styled.div`
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: -0.14px;
  color: #ffffff;
  padding: 16px 0;
  margin-bottom: 22px;
  ${(props) => (props.color ? `color: rgb(var(--${props.color}));` : '')}
  @media (max-width: 1024px) {
    font-size: 14px;
  }
`

const CopyWidthContainer = styled.div`
  @media (min-width: 1025px) {
    max-width: 400px;
  }
`

const HeaderVideoContainer = styled.div`
  width: 50%;
  height: 50vw;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 1025px) {
    width: 100%;
    height: 100vw;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    ${(props) =>
      props.fullLength ? 'width: 100%;height: calc(100vh - 110px);' : ''}
  }
`

const ShopPage = ({ data, location }) => {
  const [searchParams, setSearchParams] = useState()
  useEffect(() => {
    const search = queryString.parse(location.search)
    const terms = ['travel-bags', 'luggage', 'accessories']
    const paramKeys = Object.keys(search)
    paramKeys.map((r) => {
      const index = terms.findIndex((val) => r === val)
      if (index !== -1) {
        setSearchParams(index)
      }
    })
  }, [location.search])

  return (
    <Layout location="shop">
      <TopMagin />
      <SectionContainerTop color="dark-blue-grey" noMin={true}>
        <SectionCopy center={true}>
          <CopyWidthContainer style={{ textAlign: 'center', maxWidth: 450 }}>
            <HeaderCopyHeading large={true} color="white">
              Make it a set.
            </HeaderCopyHeading>
            <HeaderCopySubHeading color="white">
              <>
                Add to your July collection with $45 off selected luggage and
                travel bags using the code <b>BUNDLE</b>.
              </>
            </HeaderCopySubHeading>
          </CopyWidthContainer>
        </SectionCopy>
        <HeaderVideoContainer>
          <HeaderImage image={data.HeroImage.childImageSharp.gatsbyImageData} />
        </HeaderVideoContainer>
      </SectionContainerTop>
      <SEO noCrawl={true} title={`Make it A Set With A Bundle Offer`} />
      <ShopExplore noFilter={true} bundle={true} searchParams={searchParams} />
    </Layout>
  )
}

export default ShopPage

export const query = graphql`
  {
    HeroImage: file(relativePath: { eq: "bundle.jpeg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
`
